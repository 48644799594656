import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestApiService } from 'src/app/rest-api.service';
import { Router } from '@angular/router';
import { removeSpaces } from '../ErrorHandler';
@Component({
  selector: 'app-template',
  templateUrl: './template.page.html',
  styleUrls: ['./template.page.scss'],
})
export class TemplatePage implements OnInit {
  newTemplateForm: FormGroup;
  modalTitle:string;
  templateId:number;
  templateData:any = [];
  templateDetails:any = [];
  isSubmitted:boolean = false;
  toast: any;
  templateList: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private modalController: ModalController,
    private navParams: NavParams,
    private restApiService: RestApiService,
    private router: Router
  ) { }
 
  ngOnInit() {
    this.newTemplateForm = this.formBuilder.group({
      templateName: ['',  {Validators: 
        Validators.required,
        asyncValidators: removeSpaces,
      }],
      templateText: ['',  {Validators: 
        Validators.required,
        asyncValidators: removeSpaces,
      }],
      // templateName: ['', Validators.required
      // ],
      // templateText: ['', Validators.required],
    });
    this.templateId = this.navParams.data.templateId;
    if(this.templateId){
      this.getTemplateDetails();
    }
  }
 
  async closeModal() {
    await this.modalController.dismiss();
  }
  
  async submitModal() {
    this.isSubmitted = true;
    if (this.newTemplateForm.invalid) {
      return;
    }else {
     if(this.templateId){
      this.templateData = this.newTemplateForm.value;
      this.templateData['id'] = this.templateId;
      this.templateData['userId'] = localStorage.getItem('userId');
      this.restApiService.updateTemplate(this.templateId, this.templateData).subscribe(data => {
        this.showToast("Template Updated Successfully..!");
      },
      error => {
        if ((error.error.error) && error.error.error === 'invalid_token') {
          console.log("in invalid token");
          this.router.navigate(['logout']);
          return;
        } else {
          this.showToast(error.error.message);
        }
      });
     }else{
      this.templateData = this.newTemplateForm.value;
      this.templateData['userId'] = localStorage.getItem('userId');
      this.restApiService.createNewTemplate(this.templateData).subscribe(data => {
        // this.newTemplateForm.reset();
        this.showToast("Template Created Successfully..!");
      },
      error => {
        if ((error.error.error) && error.error.error === 'invalid_token') {
          console.log("in invalid token");
          this.router.navigate(['logout']);
          return;
        } else {
          this.showToast(error.error.message);
        }
      });
     }
     await this.modalController.dismiss("template created");
     
    }
  }

  get f() {
    return this.newTemplateForm.controls;
  }

  getTemplateDetails() {
    this.restApiService.getTemplateByTemplateId(this.templateId).subscribe(data => {
      this.templateDetails = data;
      this.f.templateName.setValue(this.templateDetails.templateName);
      this.f.templateText.setValue(this.templateDetails.templateText);
    })

  }

  showToast(toastMessage) {
    this.toast = this.toastController.create({
      message: toastMessage,
      animated: true,
      duration: 3000,
      cssClass: "my-custom-class"
    }).then((toastData) => {
      toastData.present();
    });
  }

}
