import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { NavParams } from '@ionic/angular';
import { CSVRecord } from 'src/app/models/csvrecord';
// import { Papa } from 'papaparse';
import * as Papa from 'papaparse';
@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.page.html',
  styleUrls: ['./custom-modal.page.scss'],
})
export class CustomModalPage implements OnInit {
  popoverflag: boolean = false;
  public value = this.navParams.get('value');
  public records: any[] = [];
 
  headerRow: [];
  csvData: [];
  dataList: any[];
  headerobj: any = [];
  @ViewChild('csvReader') csvReader: any;
  constructor(private modalCtrl: ModalController, private popoverController: PopoverController, private previewAnyFile: PreviewAnyFile, private navParams: NavParams) {
    this.headerobj = [];
  }

  ngOnInit() {
    //console.log("yes")
    //this.readFile(this.value);
    // //console.log("param"+this.value);
    // let options = {
    //   download: true,
    //   // worker: true,
    //   // header: true,
    //   complete: (parsedData, file) => {
    //     console.log("Parsed: ", parsedData, file);

    //     this.headerRow = parsedData.data.splice(0, 1)[0];
    //     this.csvData = parsedData.data;
    //   }
    // };

    // this.papa.parse(this.url, options);
    if (this.value) {

      Papa.parse(this.value, {
        // header: true,
        download: true,
        //skipEmptyLines: true,
        complete: (result, file) => {

          this.headerRow = result.data.splice(0, 1)[0];

          this.csvData = result.data;

        }
      });
    }
    // Papa.parse(this.url);

  }

  dismiss() {
    this.popoverController.dismiss();
  }
  async sendScheduler() {
    this.popoverController.dismiss();
    //     this.previewAnyFile.preview('/home/preetid/Downloads/SheetJS (2).xlsx')
    // .then((res: any) => console.log(res))
    // .catch((error: any) => console.error(error));
    this.popoverflag = true;
    const myModal = await this.modalCtrl.create({
      component: CustomModalPage,
      cssClass: 'my-custom-modal-css'
    });
    return await myModal.present();


  }
  // async openModal() {
  //   const myModal = await this.modalCtrl.create({
  //     component: CustomModalPage,
  //     cssClass: 'my-custom-modal-css'
  //   });
  //   return await myModal.present();
  // }
  readFile(fileName: any) {

    let reader = new FileReader();
    reader.readAsText(fileName);

    reader.onload = () => {
      let csvData = reader.result;

      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

      let headersRow = this.getHeaderArray(csvRecordsArray);

      this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
    };

    reader.onerror = function () {
      console.log('error is occured while reading file!');
    };
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');

    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      this.headerobj.push({ name: headers[j] });
      headerArray.push(headers[j]);
    }

    return headerArray;
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CSVRecord = new CSVRecord();

        csvRecord.First_name = curruntRecord[0].trim();
        csvRecord.Last_name = curruntRecord[1].trim();
        csvRecord.Company_name = curruntRecord[2].trim();
        csvRecord.Mobile_number = curruntRecord[3].trim();
        csvRecord.Tag_name = curruntRecord[4].trim();


        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }
  async closeModal() {
    let filterObj: any = {};


    filterObj['importStatus'] = 'notimport';
    await this.modalCtrl.dismiss(filterObj);
  }

  async submitModal() {
    let filterObj: any = {};


    filterObj['importStatus'] = 'import';
    await this.modalCtrl.dismiss(filterObj);
  }
}
