import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaginationAndSortRequest } from './models/PaginationAndSortRequest';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from './models/user';
import { tap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  userInfo: User;
  messageType = "individual";
  data: any;
  messageId;
  baseUrl = environment.baseUrl;
  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();


  private statusSubject = new Subject<any>();
  statsuObservable = this.statusSubject.asObservable();
  private customSubject1 = new Subject<any>();
  customObservable1 = this.customSubject1.asObservable();
  private customSubject2 = new Subject<any>();
  customObservable2 = this.customSubject2.asObservable();
  public currentNameSubject$ = new BehaviorSubject(this.userInfo);
  userId = window.localStorage.getItem('userId');
  //private refreshModes:Subject<void>;
  isNewReply;
  startLimit: any;
  //customerid:string;
  tollfreeNo;
  referenceId: any;
  public timerInterval: any;

  constructor(private http: HttpClient) {
    this.startLimit = 0;
    this.timerInterval = setInterval(() => {
      let token = window.localStorage.getItem('access_token');


      if (token != null) {

        this.checkNewReply().subscribe((res) => {

          //console.log("param2"+res);
          this.statusSubject.next(res);
        });

      }
    }, 10000);





    this.timerInterval = setInterval(() => {
      let token = window.localStorage.getItem('access_token');
      let apistatus = window.localStorage.getItem('apiStatus');

      if (token != null && apistatus == 'inbox') {

        this.checkNewReply().subscribe((res, infiniteScroll?) => {
          this.isNewReply = res;
          //console.log("param2"+res);
        });

        if (this.isNewReply) {
          //console.log("yes"+this.isNewReply)
          this.startLimit = 0;
          this.messageType = "individual";
          this.getMessageList(this.messageType, 0, 10).subscribe(data => {
            this.customSubject.next(data);
          });


        }
      }
    }, 20000);
    this.timerInterval = setInterval(() => {
      let token = window.localStorage.getItem('access_token');
      let apistatus = window.localStorage.getItem('apiStatus');

      if (token != null && apistatus == 'sent') {

        // this.checkNewReply().subscribe((res,infiniteScroll?) => {
        //   this.isNewReply =res;

        // });

        // if (this.isNewReply) {

        this.messageType = "grouped";

        this.startLimit = 0;
        this.getMessageList(this.messageType, 0, 10).subscribe(data => {

          this.customSubject2.next(data);
        });
      }

      // }}
    }, 3000);
    this.timerInterval = setInterval(() => {
      let customerid = window.localStorage.getItem('customerId');
      let apistatus = window.localStorage.getItem('apiStatus');
      let token = window.localStorage.getItem('access_token');
      //.log(customerid+"param"+apistatus)
      if (token != null && customerid != null && apistatus == 'inboxdetails') {


        this.messageType = "individual";

        this.startLimit = 0;
        this.messageId = '';

        this.getMessageDetails(this.messageType, window.localStorage.getItem('customerId'), this.messageId, this.referenceId, this.startLimit, 10).subscribe(data => {

          this.customSubject1.next(data);
          //this.startLimit=0;
        });
        //console.log("param"+this.data )

        //}
      }
    }, 5000);
  }

  //Message APIs
  //send message
  createMessage(msgObj: any) {
    //console.log(msgObj)
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/sendsms/message?access_token=" + window.localStorage.getItem('access_token'), msgObj);
  }
  //Newsend message
  indiualcreateMessage(msgObj: any) {
    //console.log(msgObj)
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/sendsms/chat/message?access_token=" + window.localStorage.getItem('access_token'), '', { params: msgObj });
    // return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/sendsms/chat/message",
    // {

    //   params: {

    //     'toNumber': msgObj.toNumber[0],
    //     'subject': msgObj.subject,
    //     'createdDate': msgObj.createdDate,
    //     //'recipentCount': 1,
    //   //  'messageTag': 'individual',
    //     'access_token': window.localStorage.getItem('access_token')
    //   }
    // });


  }
  //get default message list
  getMessageList(messageType, startLimit, endLimit) {
    //console.log("hello"+startLimit)
    let url = '';
    // console.log("indivaul"+messageType+url)
    if (messageType == 'grouped') {
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/groups/messages"
    } else {
      //console.log("indivaul")
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/messages"

    }
    return this.http.get(url,
      {
        params:
        {
          startLimit: startLimit.toString(),
          endLimit: endLimit.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      });


  }

  //search message from list
  searchMessage(searchMessageKey: string, startLimit, endLimit, messageType) {
    let url = '';
    if (messageType == 'grouped') {
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/groups/messages/" + searchMessageKey
    } else {
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/messages/" + searchMessageKey
    }
    return this.http.get(url,
      {
        params: {
          startLimit: startLimit.toString(),
          endLimit: endLimit.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //Sort message
  sortMessageWithSerachKey(paginationAndSortObj: PaginationAndSortRequest, messageType) {
    let url = '';
    let params = {};
    if (messageType == 'grouped') {
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/groups/" + paginationAndSortObj.sortType
    } else {
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/" + paginationAndSortObj.sortType + "/messages"
    }

    if (paginationAndSortObj.searchKey.length != 0) {
      params = {
        startLimit: paginationAndSortObj.startLimit.toString(),
        endLimit: paginationAndSortObj.endLimit.toString(),
        searchKey: paginationAndSortObj.searchKey,
        access_token: window.localStorage.getItem('access_token')
      }
    } else {
      params = {
        startLimit: paginationAndSortObj.startLimit.toString(),
        endLimit: paginationAndSortObj.endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    }
    return this.http.get(url, { params: params });
  }

  //get message details
  getMessageDetails(messageType, customerId, messageId, referenceId?, startLimit?, endLimit?) {
    let url = '';
    let params = {}
    if (messageType == 'grouped') {
      params = {
        access_token: window.localStorage.getItem('access_token')
      }
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/groups/" + referenceId + "/message"
    } else {
      params = {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
      url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + customerId + "/messages"
    }
    return this.http.get(url, { params: params });
  }

  //Send Message to the group customers
  sendGroupeMessage(messageObj) {
    
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/group/sendsms/message?access_token=" + window.localStorage.getItem('access_token'), messageObj);
  }

  //Send Message to the individual customers
  sendIndividualMessage(toNumber, messageBody, createdDate) {
    // console.log('2')
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/sendsms/" + toNumber + "/message",
      {
        params: {
          messageBody: messageBody,
          createdDate: createdDate,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //Get message history by admin id
  getMessageHistoyByAdminId(startLimit, endLimit) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/history/messages",
      {
        params:
        {
          startLimit: startLimit.toString(),
          endLimit: endLimit.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //Get Message History By Filters=
  getMessageHistoryByFilters(startLimit, endLimit, filterData) {
    let params = new URLSearchParams();
    for (let key in filterData) {
      params.set(key, filterData[key])
    }
    params.set('startLimit', startLimit.toString());
    params.set('endLimit', endLimit.toString());
    params.set('access_token', window.localStorage.getItem('access_token'));
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/history/filters/messages?" + params.toString());
  }

  //Get All Contact failed to receive message
  getFailedRecipientList(groupId) {
    return this.http.get(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/groups/failed/" + groupId + "/message?access_token=" + window.localStorage.getItem('access_token'));
  }


  //update message setting
  saveMessageSetting(msgData: any) {
    msgData['access_token'] = window.localStorage.getItem('access_token');
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/messages/settings/message", null,
      { params: msgData });
  }

  //Contact APIs
  //create new contact
  createContact(contctObj: any) {
    return this.http.post(this.baseUrl + "/customers?access_token=" + window.localStorage.getItem('access_token'), contctObj);
  }

  //get contact details
  getContactDetails(id: number) {
    return this.http.get(this.baseUrl + "/customers/" + id + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //get default contact list
  getAllContacts(startLimit: number, endLimit: number) {
    return this.http.get(this.baseUrl + "/customers/" + startLimit + "/" + endLimit,
      {
        params:
        {
          userId: window.localStorage.getItem('userId'),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }


  //Get Active Contact List
  getAllActiveContact(searchKey, startLimit, endLimit) {
    let params = {};
    if (searchKey.length != 0) {
      params = {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        searchKey: searchKey,
        access_token: window.localStorage.getItem('access_token')
      }
    } else {
      params = {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    }
    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/customers/activeCustomers',
      { params: params }
    );
  }

  //get contacts by tag id
  getContactsByTagId(tagId: Array<number>) {
    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/customers',
      {
        params:
        {
          tagId: tagId.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      }
    );
  }

  //load contact without selecting tag id(default contacts)
  getContactWithoutTagIds() {
    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/customers',
      {
        params:
        {
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //update contact
  updateContact(contactObj: any) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers?access_token=" + window.localStorage.getItem('access_token'), contactObj);
  }

  //delete contact
  deleteContact(customerIds, actionFlag: boolean) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        customerIds: customerIds,
        all: actionFlag
      },
      params:
      {
        //customerIds: customerIds,
        access_token: window.localStorage.getItem('access_token')
      }

    }


    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers", options)
    // {
    //   params:
    //   {
    //     customerIds: customerIds,
    //     access_token: window.localStorage.getItem('access_token')
    //   }
    // });
  }





  //delete message
  deleteMessage(customerIds,actionFlag: boolean) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        customerIds: customerIds,
        all: actionFlag
      },
      params:
      {
        //customerIds: customerIds,
        access_token: window.localStorage.getItem('access_token')
      }

    }


     return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/messages", options)
    //   // {
    //   params:
    //   {
    //     customerIds: customerIds,
    //     access_token: window.localStorage.getItem('access_token')
    //   }
    // });

  }


  //delete tags
  deletetags(tagIds,actionFlag: boolean) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        tagIds: tagIds,
        all: actionFlag
      },
      params:
      {
        //customerIds: customerIds,
        access_token: window.localStorage.getItem('access_token')
      }

    }


     return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags", options)
    //   // {
    //   params:
    //   {
    //     customerIds: customerIds,
    //     access_token: window.localStorage.getItem('access_token')
    //   }
    // });

  }


  //import contacts through csv file
  importCSV(file) {
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/file/upload?access_token=" + window.localStorage.getItem('access_token'), file);
  }

  //Download failed file records during import csv
  downloadFailedRecords(filePath) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/files/path",
      {
        params:
        {
          path: filePath,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //Export CSV File
  exportCsv(customerIds, actionFlag: boolean) {



    const data = {
      "customerIds": customerIds,
      "all": actionFlag
    };

    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/file/export/download?access_token=" + window.localStorage.getItem('access_token'), data);
    // return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/file/export/download",
    //   {
    //     params:
    //     {
    //       customerIds: customerIds,
    //       access_token: window.localStorage.getItem('access_token')
    //     }
    //   });
  }

  //search contact from list
  searchContactList(searchKey: string, startLimit, endLimit) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + searchKey,
      {
        params:
        {
          startLimit: startLimit.toString(),
          endLimit: endLimit.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //sort contact
  sortContactList(searchKey, startLimit, endLimit, sortOrder, sortType) {
    let params = {};
    if (searchKey.length != 0) {
      params = {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        searchKey: searchKey,
        access_token: window.localStorage.getItem('access_token')
      }
    } else {
      params = {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    }
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/" + sortType + "/" + sortOrder + "/customers",
      { params: params });
  }

  //Get All contacts by tag ids and searchKey using limit
  getContactByTagIdWithLimit(tagId: Array<number>, searchKey: string, startLimit, endLimit) {
    let params = {};
    if (searchKey.length != 0) {
      params = {
        tagId: tagId.toString(),
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        searchKey: searchKey,
        access_token: window.localStorage.getItem('access_token')
      }
    } else {
      params = {
        tagId: tagId.toString(),
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    }
    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/search/customers',
      { params: params });
  }


  //Tag APIs
  //create new tag
  createTag(tagObj: any) {
    return this.http.post(this.baseUrl + "/tags?access_token=" + window.localStorage.getItem('access_token'), tagObj);
  }
  getAllTags() {

    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags?access_token=" + window.localStorage.getItem('access_token'));
  }
  //get all tags
  getAllTag(startLimit, endLimit) {

    let params = {};

    params = {

      // startLimit: startLimit.toString(),
      //endLimit: endLimit.toString(),
      access_token: window.localStorage.getItem('access_token')
    }

    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/tags/' + startLimit.toString() + '/' + endLimit.toString(),
      { params: params });
    // return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags?access_token=" + window.localStorage.getItem('access_token'));
  }

  //search tag from list
  searchAllTagsByKey(paginationAndSortObj) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags/" + paginationAndSortObj.searchKey,
      {
        params:
        {
          startLimit: paginationAndSortObj.startLimit.toString(),
          endLimit: paginationAndSortObj.endLimit.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //sort tags
  sortAllTagsWithKey(paginationAndSortObj) {
    let params = {};
    if (paginationAndSortObj.searchKey.length != 0) {
      params = {
        startLimit: paginationAndSortObj.startLimit.toString(),
        endLimit: paginationAndSortObj.endLimit.toString(),
        searchTagKey: paginationAndSortObj.searchKey,
        access_token: window.localStorage.getItem('access_token')
      }
    } else {
      params = {
        startLimit: paginationAndSortObj.startLimit.toString(),
        endLimit: paginationAndSortObj.endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    }
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/" + paginationAndSortObj.sortType + "/tags",
      { params: params });
  }

  //delete tag
  deleteTagById(id: number) {
    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags/" + id + "?access_token=" + window.localStorage.getItem('access_token'));
  }
  updatecontactTag(tagObj: any, tagId: number) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/tags/" + "?access_token=" + window.localStorage.getItem('access_token'), tagObj);
    ///return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags/" + tagId + "?access_token=" + window.localStorage.getItem('access_token'), tagObj);
  }
  //update tag
  updateTag(tagObj: any, tagId: number) {

    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tags/" + tagId + "?access_token=" + window.localStorage.getItem('access_token'), tagObj);
  }

  //get tag details
  getTagById(id: number) {
    return this.http.get(this.baseUrl + "/tags/" + id,
      {
        params:
        {
          userId: window.localStorage.getItem('userId'),
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }


  //User APIs
  //get user details
  getUserByUserName(userName) {
    return this.http.get(this.baseUrl + "/users/usernames/" + userName + "?access_token=" + window.localStorage.getItem('access_token'));
  
  }//User APIs
  //get user details
  getAnnoymousUserByUserName(userName) {
    return this.http.get(this.baseUrl + "/usersannoymous/usernames/" + userName );
  }




  //update user
  updateUser(userObj: any, userId: number, fileData) {
    userObj['access_token'] = window.localStorage.getItem('access_token');
    return this.http.patch(this.baseUrl + "/users/" + userId, fileData, { params: userObj });
  }

  //load user list(Only Admin can access)
  getUserList() {
    return this.http.get(this.baseUrl + "/users?access_token=" + window.localStorage.getItem('access_token'));
  }

  //sort user list
  sortUserList(sortOrder: string, sortBy: string) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/" + sortOrder,
      {
        params: {
          sortBy: sortBy,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //sub-accounts
  //get sub account list
  getSubAccountList() {
    return this.http.get(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/subaccounts?access_token=" + window.localStorage.getItem('access_token'));
  }

  //Get sub account details
  getSubAccountDetails(subAccountId: number) {
    return this.http.get(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/subaccounts/" + subAccountId + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //create sub account 
  createSubAccount(accountObj: any) {
    return this.http.post(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/subaccounts?access_token=" + window.localStorage.getItem('access_token'), accountObj);
  }

  //delete Subaccount
  deleteSubaccount(subAccountId) {
    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/subaccounts/" + subAccountId + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //update Subaccount 
  updateSubaccount(subAccountId, accountObj) {
    return this.http.put(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/subaccounts/" + subAccountId + "/username?access_token=" + window.localStorage.getItem('access_token'), accountObj);

  }//update Subaccount Email Alert
  updateSubaccountEmailAlert(subAccountId, accountObj) {
    return this.http.put(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/subaccounts/emailalert/" + subAccountId + "/username?access_token=" + window.localStorage.getItem('access_token'), accountObj);

  }


  //message Template Apis
  //Get all message template by user id
  getTemplateByUserId() {
    return this.http.get(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/templates?access_token=" + window.localStorage.getItem('access_token'));
  }
  //Create new message template
  createNewTemplate(templateObj) {
    return this.http.post(this.baseUrl + "/users/" + localStorage.getItem('userId') + "/templates?access_token=" + window.localStorage.getItem('access_token'), templateObj);
  }
  //Get message template by template id
  getTemplateByTemplateId(templateId) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/templates/" + templateId + "?access_token=" + window.localStorage.getItem('access_token'));
  }
  //Update message template
  updateTemplate(templateId, templateObj) {
    return this.http.put(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/templates/" + templateId + "?access_token=" + window.localStorage.getItem('access_token'), templateObj);
  }
  //Delete message template by provided id

  DeleteTemplate(templateId: number) {
    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/templates/" + templateId + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //Check user status
  checkUserStatus(username) {
    return this.http.get(this.baseUrl + "/users/usernames/status/" + username + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //Check is new reply present
  // checkNewReply(): any {
  //    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/messages/status?access_token=" + window.localStorage.getItem('access_token')) .pipe(map(data => {
  //     let response: any = data;
  //     this.isNewReply = response;
  //    // console.log("param"+this.isNewReply)
  //    //return this.isNewReply;
  //   }));
  // }
  //Check is new reply present
  checkNewReply() {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/messages/status?access_token=" + window.localStorage.getItem('access_token'));
  }

  //Get All customers by search key from recipent count
  searchRecipient(searchKey, referenceId) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/recipentcount/" + searchKey + "/customers",
      {
        params: {
          refId: referenceId,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //Update user account status by userId
  updateUserStatus(userId, status) {
    return this.http.put(this.baseUrl + "/status/users/" + userId, {},
      {
        params: {
          status: status,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }

  //update customer phone by customer id 
  updateContactNumber(customerId, phoneNumber) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + customerId + "/" + phoneNumber, null, { params: { access_token: window.localStorage.getItem('access_token') } });
  }

  //update isLandline status
  updateIsLandlineStatus(customerId, status) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + customerId + "/landline/" + status, null, { params: { access_token: window.localStorage.getItem('access_token') } });
  }

  //update isunsubscribe status
  updateIsSubscribeMessage(customerId, status) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + customerId + "/dnd/" + status, null, { params: { access_token: window.localStorage.getItem('access_token') } });
  }

  //Update status of reply messages to mobile phones
  updateReplyStatus(status) {
    return this.http.put(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/replytomobile/status", {}, {
      params: {
        status: status,
        access_token: window.localStorage.getItem('access_token')
      }
    })
  }

  //updateCustomer message status
  updateActivationStatus(customerId, status) {
    return this.http.patch(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/customers/" + customerId + "/activationstatus/" + status, null,
      {
        params: {
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }
  //Update user welcome message status by userId
  setWelcomeMessageStatus(status) {
    return this.http.patch(this.baseUrl + "/welcomemessage/status/users/" + window.localStorage.getItem('userId'), "", {
      params: {
        status: status,
        access_token: window.localStorage.getItem('access_token')
      }
    })
  }

  //search user by searchkey
  searchUserBySearchKey(searchTerm) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/admin/" + searchTerm, {
      params: {
        startLimit: "0",
        endLimit: "1000",
        access_token: window.localStorage.getItem('access_token')
      }
    })
  }

  //delete user by userId
  deleteUser(accountId) {
    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/delete/" + accountId + "?access_token=" + window.localStorage.getItem('access_token'));
  }

  //Task Scheduler
  //Scheduler for messages
  createSchedule(msgObj) {
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks?access_token=" + window.localStorage.getItem('access_token'), msgObj);
  }

  //List all schedulers
  //Get All scheduled task by user id with limits
  listAllScheduler(startLimit, endLimit) {
    return this.http.get(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks", {
      params: {
        startLimit: startLimit,
        endLimit: endLimit,
        access_token: window.localStorage.getItem('access_token')
      }
    })
  }

  //Update scheduled task
  updateScheduler(msgObj, hashcode) {
    return this.http.put(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks/" + hashcode + "?access_token=" + window.localStorage.getItem('access_token'), msgObj);
  }

  //Delete scheduled task by provided hash code
  deleteScheduledTask(hashcode) {
    return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks/" + hashcode + "?access_token=" + localStorage.getItem('access_token'));
  }


  //list of contacts to send message - by tagid
  // Get All only phone number by tag ids
  getListContactsByTagIds(tagId: Array<number>) {
    return this.http.get(this.baseUrl + '/users/' + window.localStorage.getItem('userId') + '/customers/phonenumber',
      {
        params:
        {
          tagId: tagId.toString(),
          access_token: window.localStorage.getItem('access_token')
        }
      }
    );
  }
  sendScheduler(msgObj: any) {
    //console.log(msgObj)
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks?access_token=" + window.localStorage.getItem('access_token'),  msgObj ,config);
    // return this.http.post(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/sendsms/chat/message",
    // {

    //   params: {

    //     'toNumber': msgObj.toNumber[0],
    //     'subject': msgObj.subject,
    //     'createdDate': msgObj.createdDate,
    //     //'recipentCount': 1,
    //   //  'messageTag': 'individual',
    //     'access_token': window.localStorage.getItem('access_token')
    //   }
    // });


  }
//get default Schedule list
getSchedulegeList( startLimit, endLimit) {
  //console.log("hello"+startLimit)
  let url = '';
  // console.log("indivaul"+messageType+url)
  
    url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks"
  
    

  
  return this.http.get(url,
    {
      params:
      {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    });


}
//delete scheduler message
deleteScheduleMessage(taskId,groupId,actionFlag: boolean) {

  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: {
      taskId: taskId,
      groupId: groupId,
      all: actionFlag,
      userId:window.localStorage.getItem('userId'),
    },
    params:
    {
      //customerIds: customerIds,
      access_token: window.localStorage.getItem('access_token')
    }

  }


   return this.http.delete(this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks", options)
  //   // {
  //   params:
  //   {
  //     customerIds: customerIds,
  //     access_token: window.localStorage.getItem('access_token')
  //   }
  // });

}
//search Schedule message from list
searchScheduleMessage(searchMessageKey: string, startLimit, endLimit) {
  let url = '';
  
    url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks/" + searchMessageKey
  
  return this.http.get(url,
    {
      params: {
        startLimit: startLimit.toString(),
        endLimit: endLimit.toString(),
        access_token: window.localStorage.getItem('access_token')
      }
    });
}

//Sort message
sortScheduleMessageWithSerachKey(paginationAndSortObj: PaginationAndSortRequest) {
  let url = '';
  let params = {};
  
    url = this.baseUrl + "/users/" + window.localStorage.getItem('userId') + "/tasks/order"
  

  if (paginationAndSortObj.searchKey.length != 0) {
    params = {
      startLimit: paginationAndSortObj.startLimit.toString(),
      endLimit: paginationAndSortObj.endLimit.toString(),
      key: paginationAndSortObj.searchKey,
      sortOrder:paginationAndSortObj.sortType,
      access_token: window.localStorage.getItem('access_token'),


    }
  } else {
    params = {
      startLimit: paginationAndSortObj.startLimit.toString(),
      endLimit: paginationAndSortObj.endLimit.toString(),
      access_token: window.localStorage.getItem('access_token'),
      userId:window.localStorage.getItem('userId'),
      sortOrder:paginationAndSortObj.sortType,
    }
  }
  return this.http.get(url, { params: params });
}


  //Update montlyLimit by userId
  updateMonthlyLimit(userId, montlyLimit) {
    return this.http.put(this.baseUrl + "/users/" + userId+"/messagelimit/"+montlyLimit, {},
      {
        params: {
          
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }
  redirctLoginQuickBooks(){
    let url = '';
  
    url = "http://localhost:8080/emp/connect"
  
  return this.http.get(this.baseUrl+'/connect',
    {
      
    });
  }
//Email Alert pause And Resume
  updateEmailAlertStatus(userId,status) {
    return this.http.put(this.baseUrl + "/emailstatus/users/" + userId, {},
      {
        params: {
          status: status,
          access_token: window.localStorage.getItem('access_token')
        }
      });
  }
}


