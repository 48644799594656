import { Component, OnInit } from '@angular/core';

import { ModalController, PopoverController } from '@ionic/angular';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { NavParams } from '@ionic/angular';
@Component({
  selector: 'app-invoicepreview',
  templateUrl: './invoicepreview.page.html',
  styleUrls: ['./invoicepreview.page.scss'],
})
export class InvoicepreviewPage implements OnInit {
   previewData ;
  headerRow:string [];
  selectedSegment='New'
  checkUserRole:string='';
  headerNewInvoices:string;
  constructor(private modalCtrl: ModalController, private popoverController: PopoverController, private previewAnyFile: PreviewAnyFile, private navParams: NavParams) {
    this.headerNewInvoices="Full Name,Number,Amount,Invoice Date,Due Date";
    this.previewData=[];
    this.checkUserRole=this.navParams.get('userrole');
    this.checkUserRole=window.localStorage.getItem('import');
    
    if(this.checkUserRole=='import'){
    this.previewData=this.navParams.get('value');
    
    
    this.headerRow=[];
    var staticArray = this.headerNewInvoices.split(',');
    for (let j = 0; j < staticArray.length; j++) {
      this.headerRow.push(staticArray[j]);
    }
  }
  }

  ngOnInit() {
    
  }
  onSegmentChanged(segment) {
     if(segment.target.value=='New'){
      this.headerRow=[];
      this.headerNewInvoices="Full Name,Number,Amount,Invoice Date,Due Date";
      var staticArray = this.headerNewInvoices.split(',');
      for (let j = 0; j < staticArray.length; j++) {
        this.headerRow.push(staticArray[j]);
      }
      this.previewData=[];
      this.previewData.push(   {name:"param shinde", number:'954563325', amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020'});
      this.previewData.push(   {name:"param shinde", number:'954563325', amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020'});
      
     }else if(segment.target.value=='Updated'){
      this.headerRow=[];
      this.previewData=[];
      this.headerNewInvoices="Full Name,Number,status,Amount,Invoice Date,Due Date";
      var staticArray = this.headerNewInvoices.split(',');
      for (let j = 0; j < staticArray.length; j++) {
        this.headerRow.push(staticArray[j]);
      }
      this.previewData=[];
      this.previewData.push(   {name:"param shinde", number:'954563325', status:'unpaid',amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020',});
      this.previewData.push(   {name:"param shinde", number:'954563325', status:'paid',amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020'});
      
     }else{
      this.headerRow=[];
      this.headerNewInvoices="Full Name,Number,satus,Amount,Invoice Date,Due Date";
      var staticArray = this.headerNewInvoices.split(',');
      for (let j = 0; j < staticArray.length; j++) {
        this.headerRow.push(staticArray[j]);
      }
      this.previewData=[];
      this.previewData.push(   {name:"param shinde", number:'954563325', status:'unpaid',amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020'});
      this.previewData.push(   {name:"param shinde", number:'954563325', status:'unpaid',amount:'20 $',invoicedate:'09/30/2020',dueDate:'10/30/2020'});
        
     }
  }
  async closeModal() {
    let filterObj: any = {};


    filterObj['importStatus'] = 'notimport';
    window.localStorage.setItem('import','Notimport');
    await this.modalCtrl.dismiss(filterObj);
  }

  async submitModal() {
    let filterObj: any = {};


    filterObj['importStatus'] = 'import';
    window.localStorage.setItem('import','Notimport');
    await this.modalCtrl.dismiss(filterObj);
  }
}
