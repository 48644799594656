import { FormGroup, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of as observableOf } from 'rxjs/observable/of';
import { map } from 'rxjs/operators/map';

export function removeSpaces(control: AbstractControl) {
    return observableOf(control.value.replace(/\s/g, '') == '').pipe(
        map(response => {
            if (control && control.value && !control.value.replace(/\s/g, '').length) {
                control.setValue('');
            }
            return response;
        }),
        map(result => result ? { required: true } : null)
    );
}

export function removeSpacesValidator(control: AbstractControl) {
    return observableOf(control.value.replace(/\s/g, '') == '').pipe(
        map(response => {
            if (control && control.value && !control.value.replace(/\s/g, '').length) {
                control.setValue('');
            }
            return response;
        }),
        map(result => null)
    );
}