import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { RestApiService } from 'src/app/rest-api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
//import { type } from 'os';
@Component({
  selector: 'app-calendermodal',
  templateUrl: './calendermodal.page.html',
  styleUrls: ['./calendermodal.page.scss'],
})
export class CalendermodalPage implements OnInit {
  currentDate: string;
  public message = this.navParams.get('message');
  date: string;
  time: string;
  toast: any;
  newContactForm: FormGroup;
  selectedDate: any;
  validateTime: boolean = false;
  year: string;
  isSubmitted = false;
  hour: string;
  minute: string;
  monday: string;
  currentTimezoneName: any;
  month: string;
  validateDate: boolean = false;
  toNumber: any = [];
  schedulerDate: string;
  day: string;
  messageFormData: any = {};
  scheduleTime: string;
  type: any;
  tagIDs : string;
  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, private modalCtrl: ModalController, private navParams: NavParams, private modalController: ModalController, private restApi: RestApiService, private toastController: ToastController) {
    this.toNumber = this.navParams.get('toNumber');
    this.tagIDs = this.navParams.get('tagIDs')
    console.log("const time",this.tagIDs);

    //     let date  = moment();

    //     this.currentDate= date.format(' MMM DD YYYY');
    //     //this.time=date.format("hh:mm") // 

    //  this.time = date.hour() + ':' + date.minutes() ;
    // this.time = this.time + ((date.hour()) >= 12 ? ' PM' : ' AM');
    let timezone = window.localStorage.getItem('timezone');

    this.type = moment().tz(timezone);
    let date = moment().tz(timezone);
    this.validateDate = false;
    this.currentDate = date.format('MMM DD YYYY');
    this.time = date.add(1, 'hours').format("hh:mm A");
    this.scheduleTime = this.time;
    this.schedulerDate = date.format('MM/DD/YYYY');
    //this.time = date.hour() + ':' + date.minutes();
    // this.time = this.time + ((date.hour()) >= 12 ? ' PM' : ' AM');
    //console.log("constructor current time" + this.time)
    this.newContactForm = this.formBuilder.group({
      currentdate: new FormControl(''),
      currenttime: new FormControl(''),


    });

  }

  ngOnInit() {
  }
  async closeModal() {
    await this.modalCtrl.dismiss();
  }
  async selectDate(event) {
    let formateDate;
    this.currentDate = event.format('MMM DD YYYY');
    // let timezone = window.localStorage.getItem('timezone');
    this.schedulerDate = event.format('MM/DD/YYYY');

    // this.validateDate == true;
    this.timeValidatorNew(this.scheduleTime, this.schedulerDate);
    this.datevalidater(this.schedulerDate, this.scheduleTime);




  }
  async onSubmit() {

    let filterObj: any = {};
    this.isSubmitted = true;
    if (this.newContactForm.invalid) {

      return;
    } else {
      if (this.validateDate && this.validateTime) {

        return;
      } else if (this.timeValidator(this.newContactForm.get('currenttime').value, this.datePipe.transform(this.newContactForm.get('currentdate').value, 'MM/dd/yyyy')) || this.datevalidater(this.datePipe.transform(this.newContactForm.get('currentdate').value, 'MM/dd/yyyy'))) {
        return;
      } else {
        var current_date = this.newContactForm.get('currentdate').value.split(" ");

        this.day = current_date[1].trim();
        //this.month == current_date[1];
        this.year = current_date[2].trim();

        var current_time = this.newContactForm.get('currenttime').value.split(":");

        const number = moment(current_time[0] + "" + current_time[1], ["hh:mm A"]).format("HH:mm");


        current_time = number.split(":");

        this.hour = current_time[0].trim();
        if (this.hour.length == 2 && this.hour.charAt(0) == '0') {
          this.hour = this.hour.replace(this.hour.charAt(0), '');
        }
        this.minute = current_time[1];

        if (this.minute.includes('00')) {
          this.minute.replace(/00/g, '0');
        }


        this.minute = this.minute.trim();
        this.messageFormData = {
          'subject': this.message,
          'day': this.day,
          'month': current_date[0].trim(),
          'hour': this.hour,
          'minute': this.minute,
          'toNumber': this.toNumber,
          'dayOfWeek': '',
          "second": '0',
          'taskName': '',
          "timeZone": window.localStorage.getItem('timezone'),
          'year': this.year,
          "userId": window.localStorage.getItem('userId'),
          'subAccountNo':localStorage.getItem('subAccountNo').toString(),
          'tagId': this.tagIDs
        }

        this.restApi.sendScheduler(this.messageFormData).subscribe(data => {
          this.showToast("Scheduler assigned ");
          this.router.navigate(['scheduler-details']);

        },
          error => {
            if ((error.error.error) && error.error.error === 'invalid_token') {
              console.log("in invalid token");

              //this.router.navigate(['logout']);
              return;
            } else {
              this.showToast(error.error.message);
            }
          });
        await this.modalController.dismiss(filterObj);
      }

    }

  }
  showToast(toastMessage) {
    this.toast = this.toastController.create({
      message: toastMessage,
      animated: true,
      duration: 3000,
      cssClass: "my-custom-class"
    }).then((toastData) => {
      toastData.present();
    });

  }


  timeValidatorNew(currentTime, currentDate) {
    // console.log("actual" + currentTime)
    currentDate = this.datePipe.transform(currentDate, 'MM/dd/yyyy')


    //const pattern = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/;
    const pattern = /^(0[1-9]|1[012])(:[0-5]\d) [AP][M]$/
    let timezone = window.localStorage.getItem('timezone');
    this.scheduleTime = currentTime;

    if (typeof currentTime === "string" &&
      currentTime.includes(":")) {

      var dateArray = currentTime.split(":");





      if (dateArray.length == 2 && dateArray[0].length == 1) {
        currentTime = "0" + dateArray[0] + ":" + dateArray[1];

      } else if (dateArray[0].length == 1) {
        currentTime = "0" + dateArray[0] + ": 0";
      }

    }

    if (pattern.test(currentTime)) {


      let currentReplaceTime;

      currentTime = currentTime.trim();

      var current_time = currentTime.split(":");
      const number = moment(current_time[0] + "" + current_time[1], ["hh:mm A"]).format("HH:mm A");
      currentReplaceTime = number.replace(/AM/g, ' ');
      currentReplaceTime = number.replace(/PM/g, ' ');


      var time1InMinutesForTime1 = this.getTimeAsNumberOfMinutes(currentReplaceTime);
      var time1InMinutesForTime2 = this.getTimeAsNumberOfMinutes(moment().tz(timezone).format("HH:mm"));

      if ((time1InMinutesForTime1 > time1InMinutesForTime2 && moment().tz(timezone).format('MM/DD/YYYY') == currentDate) || (time1InMinutesForTime1 == time1InMinutesForTime2 && moment().tz(timezone).format('MM/DD/YYYY') == currentDate)) {
        this.time = currentTime;
        this.validateTime = false;


      } else if (new Date(moment().tz(timezone).format('MM/DD/YYYY')) < new Date(currentDate)) {

        this.validateTime = false;
        this.time = currentTime;
      }
      //} if (time1InMinutesForTime1)
      //   if ((moment().tz(timezone).format("HH:mm A").replace(/ /g, ' ') == number)
      //     || (moment().tz(timezone).format("HH:mm A").replace(/ /g, ' ') < number)
      //   ) {
      //     this.time = currentTime;
      //     this.validateTime = false;

      //   }
      else {
        this.time = currentTime;
        this.validateTime = true;

      }

    } else {
      this.time = currentTime;
      this.validateTime = true;

    }

    return this.validateTime;
  }

  // compareTime(str1, str2) {
  //   if (str1 === str2) {
  //     return 0;
  //   }
  //   var time1 = str1.split(':');
  //   var time2 = str2.split(':');
  //   if (Number(time1[0]) > Number(time2[0])) {

  //     return 1;

  //   } else if (Number(time1[0]) == Number(time2[0]) && Number(time1[1]) > Number(time2[1])) {

  //     return 1;

  //   } else {

  //     return -1;

  //   }
  // }
  getTimeAsNumberOfMinutes(time) {
    //  console.log(time)
    var timeParts = time.split(":");
    timeParts[1] = timeParts[1].replace(/AM/g, ' ');
    timeParts[1] = timeParts[1].replace(/PM/g, ' ');
    var timeInMinutes = Number((timeParts[0] * 60)) + Number(timeParts[1]);
    //console.log(timeInMinutes)

    return timeInMinutes;
  }

  datevalidater(currentDate, scheduleTime?): boolean {
    let timezone = window.localStorage.getItem('timezone');
    const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    if ((pattern.test(currentDate) && moment().tz(timezone).format('MM/DD/YYYY') == currentDate) ||
      (pattern.test(currentDate) && new Date(currentDate) > new Date(moment().tz(timezone).format('MM/DD/YYYY')))) {
      this.validateDate = false;
      this.currentDate = this.datePipe.transform(currentDate, 'MMM dd yyyy');
      this.schedulerDate = this.datePipe.transform(currentDate, 'MM/DD/YYYY');

    } else if (moment(currentDate, 'MMM dd yyyy').isValid() && currentDate.length == 11) {
      let dateFormat = this.datePipe.transform(currentDate, 'MM/dd/yyyy')
      this.schedulerDate = dateFormat;

      if (dateFormat == moment().tz(timezone).format('MM/DD/YYYY') ||
        new Date(dateFormat) > new Date(moment().tz(timezone).format('MM/DD/YYYY'))) {
        this.timeValidatorNew(scheduleTime, this.schedulerDate)
        this.validateDate = false;
        this.currentDate = this.datePipe.transform(currentDate, 'MMM dd yyyy');
      }
      else {
        this.validateDate = true;
      }
    }
    else {
      this.validateDate = true;
    }
    return this.validateDate;
  }




  timeValidator(currentTime, currentDate): boolean {
    // console.log("actual" + currentTime)
    //const pattern = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/;
    const pattern = /^(0[1-9]|1[012])(:[0-5]\d) [AP][M]$/
    let timezone = window.localStorage.getItem('timezone');



    if (typeof currentTime === "string" &&
      currentTime.includes(":")) {

      var dateArray = currentTime.split(":");





      if (dateArray.length == 2 && dateArray[0].length == 1) {
        currentTime = "0" + dateArray[0] + ":" + dateArray[1];

      } else if (dateArray[0].length == 1) {
        currentTime = "0" + dateArray[0] + ": 0";
      }

    }

    if (pattern.test(currentTime)) {


      let currentReplaceTime;

      currentTime = currentTime.trim();

      var current_time = currentTime.split(":");
      // const number = moment(current_time[0] + "" + current_time[1], ["hh:mm A"]).format("HH:mm A");
      // currentReplaceTime = number.replace(/AM/g, ' ');
      // currentReplaceTime = number.replace(/PM/g, ' ');
      // //var current_time = moment().tz(timezone).format("hh:mm A").split(" ");
      // // console.log(number+"yes"+moment().tz(timezone).format("HH:mm A").replace(/ /g, ' '))
      // if ((moment().tz(timezone).format("HH:mm A").replace(/ /g, ' ') == number)
      //   || (moment().tz(timezone).format("HH:mm A").replace(/ /g, ' ') < number)
      // ) {
      //   this.time = currentTime;
      //   this.validateTime = false;

      //} 

      const number = moment(current_time[0] + "" + current_time[1], ["hh:mm A"]).format("HH:mm A");
      currentReplaceTime = number.replace(/AM/g, ' ');
      currentReplaceTime = number.replace(/PM/g, ' ');


      var time1InMinutesForTime1 = this.getTimeAsNumberOfMinutes(currentReplaceTime);
      var time1InMinutesForTime2 = this.getTimeAsNumberOfMinutes(moment().tz(timezone).format("HH:mm"));

      if (time1InMinutesForTime1 > time1InMinutesForTime2 || time1InMinutesForTime1 == time1InMinutesForTime2) {
        this.time = currentTime;
        this.validateTime = false;
      }
      else if (moment().tz(timezone).format('MM/DD/YYYY') < currentDate) {
        this.time = currentTime;
        this.validateTime = false;

      }
      //else if (this.compareTime(currentReplaceTime, moment().tz(timezone).format("HH:mm").replace(/ /g, ' ')) == 1 || this.compareTime(currentReplaceTime, moment().tz(timezone).format("HH:mm").replace(/ /g, ' ')) == 0) {
      //   this.time = currentTime;
      //   this.validateTime = false
      // }
      else {
        this.validateTime = true;

      }

    } else {

      this.validateTime = true;

    }

    return this.validateTime;
  }

}