// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   
  //baseUrl:'http://localhost:8000/api/v1/canarytext'
  baseUrl: 'https://app.canarytext.com:8443/api/v1/canarytext' //live
  //baseUrl:'http://104.43.249.219:8000/api/v1/canarytext' //test server
  //  baseUrl:'http://192.168.0.67:8000/api/v1/canarytext'
  // baseUrl:'http://192.168.0.12:8000/api/v1/canarytext'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
