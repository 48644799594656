import { Component, OnInit } from '@angular/core';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { ModalController, ToastController, NavParams } from '@ionic/angular';
import { CalendermodalPage } from '../calendermodal/calendermodal.page';
import { RestApiService } from 'src/app/rest-api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-timezonemodal',
  templateUrl: './timezonemodal.page.html',
  styleUrls: ['./timezonemodal.page.scss'],
})
export class TimezonemodalPage implements OnInit {
  public userTz: string;
  tomorrow: string;
  year: string;
  toast: any;
  monday: string;
  currentTimezoneName: any;
  month: string;
  public message = this.navParams.get('message');
  toNumber: any = [];
  tagIDs : string;
  time: string;
  day: string;
  mondayMonth: string;
  mondayDay: string
  mondayYear: string;
  messageFormData: any = {};
  constructor(private modalController: ModalController,private route: ActivatedRoute,private router: Router, private navParams: NavParams, private restApi: RestApiService, private toastController: ToastController) {
   console.log("const time")
    //this.userTz = moment.tz.guess();
    ///this.currentTimezoneName=moment.tz(this.userTz).zoneAbbr() ;
    this.toNumber = this.navParams.get('toNumber');
    this.tagIDs = this.navParams.get('tagIDs')
    console.log("const time",this.tagIDs);

    //let date  = moment().add(1,'days');
    //this.tomorrow=  date.format(' MMM DD');
    //this.year=date.format('YYYY');
    //let mondayDate=moment().startOf('isoWeek').add(1, 'week');
    //this.monday=mondayDate.format(' MMM DD');
    let timezone = window.localStorage.getItem('timezone');
    
    if (timezone == 'America/Chicago') {
      this.currentTimezoneName = 'Central Time (CT)';
    } else if (timezone == 'America/Los_Angeles') {
      this.currentTimezoneName = 'Pacific Time (PT)';
    } else if (timezone == 'America/New_York') {
      this.currentTimezoneName = 'Eastern Time (ET)';
    } else if (timezone == 'America/Denver') {
      this.currentTimezoneName = 'Mountain Time (MT)';
    } else if (timezone == 'Pacific/Honolulu') {
      this.currentTimezoneName = 'Hawaii Time (HT)';
     }else if(timezone=='America/Anchorage'){
      this.currentTimezoneName = 'Alaska Time (AKT)';
    } //else if (timezone == 'America/Anchorage') {
    //   this.currentTimezoneName = 'AKDT';
    // } else if (timezone == 'America/Phoenix') {
    //   this.currentTimezoneName = 'MST';
    // }
    this.day = moment().add(1, 'days').tz(timezone).format("DD");
    this.month = moment().add(1, 'days').tz(timezone).format("MMM");
    this.tomorrow = moment().add(1, 'days').tz(timezone).format("MMM DD");
    this.monday = moment().startOf('isoWeek').add(1, 'week').tz(timezone).format("MMM DD");
    this.year = moment().add(1, 'days').tz(timezone).format("YYYY");
    this.mondayDay = moment().startOf('isoWeek').add(1, 'week').tz(timezone).format("DD");
    this.mondayMonth = moment().startOf('isoWeek').add(1, 'week').tz(timezone).format("MMM");
    this.mondayYear = moment().startOf('isoWeek').add(1, 'week').tz(timezone).format("YYYY");

  }


  ngOnInit() {
  }
  async closeModal() {
    await this.modalController.dismiss();
  }
  async openCalender() {
    this.modalController.dismiss();



    const myModal = await this.modalController.create({
      component: CalendermodalPage,
      cssClass: 'calender',
      backdropDismiss: false,
      componentProps: { message:this.message ,toNumber: this.toNumber,tagIDs: this.tagIDs},
    });
    return await myModal.present();


  }
  async submitModal(day) {
console.log("submit");
    let filterObj: any = {};
    if (day == 'tomorrowMorning') {
      this.time = "8";
      this.messageFormData = {
        'subject': this.message,
        'day': this.day,
        'month': this.month,
        'hour': this.time,
        'minute': '0',
        'toNumber': this.toNumber,
        'dayOfWeek': '',
        "second": '0',
        'taskName': '',
        'year': this.year,
        "timeZone": window.localStorage.getItem('timezone'),
        "userId": window.localStorage.getItem('userId'),
        'subAccountNo':localStorage.getItem('subAccountNo').toString(),
        'tagId': this.tagIDs
      }

    } else if (day == 'tomorrowAfternoon') {
      this.time = "13";
      this.messageFormData = {
        'subject': this.message,
        'day': this.day,
        'month': this.month,
        'hour': this.time,
        'minute': '0',
        'toNumber': this.toNumber,
        'dayOfWeek': '',
        "second": '0',
        'taskName': '',
        'year': this.year,
        "timeZone": window.localStorage.getItem('timezone'),
        "userId": window.localStorage.getItem('userId'),
        'subAccountNo':localStorage.getItem('subAccountNo').toString(),
        'tagId': this.tagIDs
      }
    } else {
      this.time = "8";
      this.messageFormData = {
        'subject': this.message,
        'day': this.mondayDay,
        'month': this.mondayMonth,
        'hour': this.time,
        'minute': '0',
        'toNumber': this.toNumber,
        'dayOfWeek': '',
        "second": '0',
        'taskName': '',
        'year': this.mondayYear,
        "timeZone": window.localStorage.getItem('timezone'),
        "userId": window.localStorage.getItem('userId'),
        'subAccountNo':localStorage.getItem('subAccountNo').toString(),
        'tagId': this.tagIDs
      }
    }
console.log("time",this.messageFormData)
    this.restApi.sendScheduler(this.messageFormData).subscribe(data => {
      this.showToast("Scheduler assigned ");
      console.log("Api")
      this.router.navigate(['scheduler-details']);

    },
      error => {
        if ((error.error.error) && error.error.error === 'invalid_token') {
          console.log("in invalid token");

          //this.router.navigate(['logout']);
          return;
        } else {
          this.showToast(error.error.message);
        }
      });
    await this.modalController.dismiss(filterObj);
  }
  showToast(toastMessage) {
    this.toast = this.toastController.create({
      message: toastMessage,
      animated: true,
      duration: 3000,
      cssClass: "my-custom-class"
    }).then((toastData) => {
      toastData.present();
    });
  }
}



