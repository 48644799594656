import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController, NavParams } from '@ionic/angular';
import { TimezonemodalPage } from '../timezonemodal/timezonemodal.page';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.page.html',
  styleUrls: ['./scheduler.page.scss'],
})
export class SchedulerPage implements OnInit {
  public message = this.navParams.get('message');
  toNumber:any = [];
  tagIDs: string;
  
  constructor(private popoverController: PopoverController,private modalCtrl: ModalController,private navParams: NavParams) { 
    this.toNumber  = this.navParams.get('toNumber');
    this.tagIDs = this.navParams.get('tagIDs');
  }

  ngOnInit() {
  }
  dismiss() {
    this.popoverController.dismiss();
  }
  async sendScheduler(){
    this.popoverController.dismiss();
    const modal = await this.modalCtrl.create({
      component: TimezonemodalPage,
      cssClass:'timezone-modal',
      backdropDismiss:false,
      componentProps: { message:this.message ,toNumber: this.toNumber, tagIDs:this.tagIDs},
      //componentProps: { value: filePath }
    });
    console.log("send scheduler");
    // modal.onDidDismiss().then((dataReturned) => {
      
    //   if (dataReturned.data['importStatus'] == 'import') {
        
    //   }
    // });

    return await modal.present();
}

}