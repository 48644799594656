import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { RestApiService } from './rest-api.service';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})


export class CommonService {

  isLoading = false;
  isNewReply;
  public timerInterval: any;
   //Call api for every 30 sec to check is there any new reply
  constructor(public loadingController: LoadingController,
    private restApiService: RestApiService) {
      // this.timerInterval = setInterval(() => {
      //   this.checkNewReply();
      // }, 10000);
     }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 70000,
      // spinner: 'dots'
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log());
        }
      });
    });
  }

  async dismiss() {
    console.log()
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log());
    // return this.loadingController.dismiss();
  }
 //Call api for every 30 sec to check is there any new reply
 checkNewReply() {
  this.restApiService.checkNewReply().subscribe(data => {
    let response: any = data;
    this.isNewReply = response;
  },error => {
  // clearInterval(this.timerInterval);
  })
  // return this.isNewReply;
}
}
