import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: 'inbox-message', loadChildren: './pages/inbox-message/inbox-message.module#InboxMessagePageModule' },
  { path: 'scheduler-details', loadChildren: './pages/scheduler-details/scheduler-details.module#SchedulerDetailsPageModule' },
  { path: 'invoice-details', loadChildren: './pages/invoiceimport/invoiceimport.module#InvoiceimportPageModule' },
  { path: 'inbox-detailsanonymous/:customerId/:userName/:subaccount', loadChildren: './pages/anonymoususer/anonymoususer.module#AnonymoususerPageModule' },
  { path: 'inbox-details/:customerId', loadChildren: './pages/inbox-details/inbox-details.module#InboxDetailsPageModule' },
  { path: 'messages', loadChildren: './pages/messages/messages.module#MessagesPageModule' },
  { path: 'new-message/:messageType', loadChildren: './pages/new-message/new-message.module#NewMessagePageModule' },
  { path: 'new-contact', loadChildren: './pages/new-contact/new-contact.module#NewContactPageModule' },
  { path: 'contacts', loadChildren: './pages/contacts/contacts.module#ContactsPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'tag', loadChildren: './pages/tag/tag.module#TagPageModule' },
  { path: 'account-setting', loadChildren: './pages/account-setting/account-setting.module#AccountSettingPageModule' },
  { path: 'message-details/:messageType/:detailId/:refId', loadChildren: './pages/message-details/message-details.module#MessageDetailsPageModule' },
  { path: 'message-details/:messageType/:detailId', loadChildren: './pages/message-details/message-details.module#MessageDetailsPageModule' },
  { path: 'new-tag', loadChildren: './pages/new-tag/new-tag.module#NewTagPageModule' },
  { path: 'edit-tag/:id', loadChildren: './pages/new-tag/new-tag.module#NewTagPageModule' },
  { path: 'user-list', loadChildren: './pages/user-list/user-list.module#UserListPageModule' },
  { path: 'account-setting/:userName', loadChildren: './pages/account-setting/account-setting.module#AccountSettingPageModule' },
  { path: 'login', loadChildren: './pages/auth/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './pages/auth/register/register.module#RegisterPageModule' },
  { path: 'logout', loadChildren: './pages/auth/logout/logout.module#LogoutPageModule' },
  { path: 'edit-contact/:id', loadChildren: './pages/edit-contact/edit-contact.module#EditContactPageModule' },
  { path: 'template', loadChildren: './pages/template/template.module#TemplatePageModule' },
 
  { path: 'sub-accounts', loadChildren: './pages/sub-accounts/sub-accounts.module#SubAccountsPageModule' },
  { path: 'message-history', loadChildren: './pages/message-history/message-history.module#MessageHistoryPageModule' },
  { path: 'history-filter', loadChildren: './pages/history-filter/history-filter.module#HistoryFilterPageModule' },
  { path: 'scheduler', loadChildren: './pages/scheduler/scheduler.module#SchedulerPageModule' },
  // { path: 'loadmessage', loadChildren: './loadmessage/loadmessage.module#LoadmessagePageModule' },
  { path: 'loadmessage', loadChildren: './pages/loadmessage/loadmessage.module#LoadmessagePageModule' },
  { path: 'custom-modal', loadChildren: './pages/custom-modal/custom-modal.module#CustomModalPageModule' },
  { path: 'timezonemodal', loadChildren: './pages/timezonemodal/timezonemodal.module#TimezonemodalPageModule' },
  { path: 'calendermodal', loadChildren: './pages/calendermodal/calendermodal.module#CalendermodalPageModule' },
  { path: 'invoiceimport', loadChildren: './pages/invoiceimport/invoiceimport.module#InvoiceimportPageModule' },
  { path: 'invoicepreview', loadChildren: './pages/invoicepreview/invoicepreview.module#InvoicepreviewPageModule' },
  { path: 'anonymoususer', loadChildren: './pages/anonymoususer/anonymoususer.module#AnonymoususerPageModule' },
  //{ path: 'schedulerdetails', loadChildren: './pages/schedulerdetails/schedulerdetails.module#SchedulerdetailsPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
