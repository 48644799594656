import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule }    from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicRatingModule } from 'ionic4-rating';
import { DatePipe } from '@angular/common';
import { CommonService } from './common.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import { IonicSelectableModule } from 'ionic-selectable';
import { TemplatePageModule } from './pages/template/template.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HistoryFilterPageModule } from './pages/history-filter/history-filter.module';
import { CustomModalPage } from './pages/custom-modal/custom-modal.page';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { CustomModalPageModule } from './pages/custom-modal/custom-modal.module';
import { SchedulerPageModule } from './pages/scheduler/scheduler.module';
import { TimezonemodalPageModule } from './pages/timezonemodal/timezonemodal.module';
import { CalendarModule } from "ion2-calendar";
import { CalendermodalPageModule } from './pages/calendermodal/calendermodal.module';
import { InvoicepreviewPageModule } from './pages/invoicepreview/invoicepreview.module';

@NgModule({
  declarations: [AppComponent],
  
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CalendarModule,
    HttpClientModule,
    IonicRatingModule,
    CalendermodalPageModule,
    CustomModalPageModule,
    InvoicepreviewPageModule,
    TimezonemodalPageModule,
    SchedulerPageModule,
    IonicSelectableModule, 
    TemplatePageModule,
    HistoryFilterPageModule,
    NgxDatatableModule
  ],
  providers: [
    StatusBar,
    DatePipe,
    SplashScreen,
    LoadingController,
    CommonService,
    PreviewAnyFile,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeStorage,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
