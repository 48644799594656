import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-history-filter',
  templateUrl: './history-filter.page.html',
  styleUrls: ['./history-filter.page.scss'],
})
export class HistoryFilterPage implements OnInit {
  firstName: String = '';
  lastName: String = '';
  phoneNumber: String = '';
  messageStatus: String = '';
  date: String = '';
  constructor(private modalController: ModalController,private datePipe: DatePipe) {
   }

  ngOnInit() {

  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async submitModal() {
    let filterObj:any={};
    if (this.firstName == "" && this.lastName == "" && this.phoneNumber == "" && this.date == "" && this.messageStatus == "") {
      filterObj = null;
    } else {
      if(this.firstName){
        filterObj['firstName'] = this.firstName;
      }
      if(this.lastName){
        filterObj['lastName'] = this.lastName;
      }
      if(this.phoneNumber){
        filterObj['phoneNumber'] = this.phoneNumber;
      }
      if(this.date){
        filterObj['date'] = this.datePipe.transform(this.date, 'yyyy-MM-dd');
      }
      if(this.messageStatus){
        filterObj['messageStatus'] = this.messageStatus;
      }
      
    }
    await this.modalController.dismiss(filterObj);
  }

  //validation for keypress event.accept numbers
  onKeyPressEvent(event) {
    const pattern = /[0-9\+\-\ ]/;
  
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
